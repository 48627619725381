import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "components/layout";
import SEO from "components/seo";
import { jsx, keyframes } from "@emotion/core";
import { colors } from "style/theme";
import { mq } from "../utils/helper";
import LocalizedLink from "components/LocalizedLink";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { rhythm, scale } from "utils/typography";
import useTranslations from "hooks/useTranslations";
import PressCoverage from "components/homepage/homepage-press-coverage";
import Updates from "../components/homepage/homepage-updates";
import WhatWeDo from "../components/homepage/homepage-whatwedo";
import TypedString from "../components/typed";
import CookieBanner from "components/CookieBanner";

const fading = keyframes`
  0%   { transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,20px); opacity: 0; }`;

function HomeIntro() {
  const tr = useTranslations();
  return (
    <>
      <CookieBanner />
      <div
        css={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          css={{
            color: colors.light,
            maxWidth: "800px",
            margin: "auto",
            height: "80vh",
            [mq[1]]: {
              height: "auto",
            },
          }}
        >
          <h1
            css={{
              margin: 0,
              color: colors.primary,
              textAlign: "center",
            }}
          >
            <span css={{ marginRight: "1rem" }}>{tr("We")}</span>
            <TypedString
              strings={["archive", "restore", "investigate", "build", "train"]}
            />
          </h1>
          <p
            css={{
              ...scale(0.4),
              textAlign: "center",
              margin: `${rhythm(5)} auto ${rhythm(2.5)} auto`,
              letterSpacing: "0.29px",
              lineHeight: "34px",
              [mq[1]]: {
                textAlign: "left",
                margin: "4rem 2rem",
              },
            }}
          >
            Mnemonic works globally to help human rights defenders effectively
            use digital documentation of human rights violations and
            international crimes to support advocacy, justice and
            accountability.
          </p>
          <div
            css={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LocalizedLink
              to="contact-us"
              css={{
                textAlign: "center",
                width: "170px",
                height: "38px",
                color: colors.light,
                border: "3px solid #00FFFF",
                opacity: 0.8,
                paddingTop: "0.3rem",
                fontSize: "20px",
                ": hover": {
                  color: colors.primary,
                },
              }}
            >
              Get in touch
            </LocalizedLink>
          </div>
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              marginTop: "7rem",
              [mq[1]]: {
                display: "none",
              },
            }}
          >
            <AnchorLink href="#what-we-do">
              <div
                css={{
                  backgroundImage:
                    "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  animation: `${fading} 2s ease-in-out infinite`,
                  width: "65px",
                  height: "65px",
                }}
              />
            </AnchorLink>
          </div>
        </div>
      </div>
    </>
  );
}

function Index({ data }) {
  const press = data.press.edges.map((p) => p.node);
  return (
    <div>
      <Layout>
        <SEO title="Home" />
        <div
          css={{
            background:
              "linear-gradient(180deg, #000000 0%, #012A2A 55.42%, #000000 100%);",
          }}
        >
          <HomeIntro />
          <WhatWeDo />
          <Updates />
          <PressCoverage nodes={press} />
        </div>
      </Layout>
    </div>
  );
}

Index.propTypes = {
  data: PropTypes.object,
};

export default Index;

export const pageQuery = graphql`
  query Index($locale: String!) {
    press: allPressJson(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          link
          title
          date
          image
        }
      }
    }
  }
`;
